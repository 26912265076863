.sidebar-container {
    position: absolute;
    width: 340px;
    /* height: 85%; */
    top: 0; 
    margin: 15px;
    padding: 30px;
    box-shadow: 5px 5px 10px grey;
    background-color: white;
    /* overflow: scroll; */
  }

.logo-style {
  width: 45%;
  display: block;
  margin-left: auto;
}

